<template>
  <div>
    <v-card-text>

      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            ref="password"
            v-model=dataTef.password
            label="Password"
            prepend-inner-icon="mdi-account-tie"

            required
          />
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >

          <v-text-field
            ref="Username"
            v-model=dataTef.username
            label="Username"
            prepend-inner-icon="mdi-account-tie"

            required
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            ref="client_id"
            v-model=dataTef.client_id
            label="Client_Id"
            prepend-inner-icon="mdi-account-tie"

            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            ref="url_token"
            v-model=dataTef.url_token
            label="Url_Token"
            prepend-inner-icon="mdi-account-tie"

            required
          />
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            ref="grant_type"
            v-model=dataTef.grant_type
            label="Grant_Type"
            prepend-inner-icon="mdi-account-tie"

            required
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            ref="url_services"
            v-model=dataTef.url_services
            label="Url_Services"
            prepend-inner-icon="mdi-account-tie"

            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            ref="client_secret"
            v-model=dataTef.client_secret
            label="Client_Secret"
            prepend-inner-icon="mdi-account-tie"

            required
          />
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            ref="codigo_comercio"
            v-model=dataTef.codigo_comercio
            label="Codigo_Comercio"
            prepend-inner-icon="mdi-account-tie"
            required
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            ref="codigo_terminal"
            v-model=dataTef.codigo_terminal
            label="Codigo_Terminal"
            prepend-inner-icon="mdi-account-tie"
            required
          />
        </v-col>
      </v-row>
      <v-card-actions class="justify-end">

        <v-btn
          text
          style="background-color:rgb(204 33 40);color:white !important"
          @click="updateDataTef()"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
      <v-alert
        v-show="successAlert"
        type="success"
        text
      >
        {{ msgSuccessAlert }}
      </v-alert>
    </v-card-text>

  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'campoSmartPos',
    props: ['dataTef', 'token_key', 'code_store'],

    data () {
      return {
        dataUpdateTef: [],
        msgSuccessAlert: 'Datos actualizados exitosamente',
        successAlert: false,
      }
    },
    computed: {
      ...mapState('credentialsTef', [
        'updateStatusTimeOutResponse'
      ]),

      updateStatusResponse () {
        return this.$store.state.credentialsTef.updateStatusResponse
      },
    },
      watch:
        {
          updateStatusResponse  () {
            if (this.updateStatusResponse === true) {
              this.generateAlertSuccess()
              this.$store.dispatch('credentialsTef/setUpdateResponseCredentials', false)
            }
          },
        },
    methods: {
      generateAlertSuccess () {
        this.successAlert = true
        setTimeout(() => {
          this.successAlert = false
        }, 3000)
      },
      async updateDataTef () {
        this.dataUpdateTef.dataCredentials = this.dataTef
        this.dataUpdateTef.token_key = this.token_key
        this.dataUpdateTef.code_store = this.code_store
        this.dataUpdateTef.user = JSON.parse(atob(localStorage.getItem('uid'))).user
        this.dataUpdateTef.observation = 'Updated Credentials'
        await this.$store.dispatch('credentialsTef/updateCredentials', this.dataUpdateTef)
      },
    },
  }
</script>

<style scoped>

</style>
